import React from "react";

const PageIntro = props => {
    return (
        <div className="service-content-full">
            {props.icon && <img src={props.icon} alt="icon-red" />}
            {props.heading && <h4>{props.heading}</h4>}
        </div>
    );
};

export default PageIntro;
