import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Link } from "gatsby";

import CalltoAction from "../components/CalltoAction";
import PageIntro from "../components/PageIntro";
import AlternatingRowsContent from "../components/AlternatingRows";

import $ from "jquery/dist/jquery";

class Products extends React.Component {
  componentDidMount() {
    var $menu = $(".main-menu ul").clone();
    $("#mobile-menu").html($menu);
    $("#mobile-menu ul li:last-child").remove();

    $(".mobile-menu-icon").on("click", function() {
      $(".mobile-menu-wrap").toggleClass("mobile-menu-show");
    });

    $(".close-btn").on("click", function() {
      $(".mobile-menu-wrap").removeClass("mobile-menu-show");
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="google-site-verification"
            content="YAjmKXP8olHAA_JpbqTrbgHbFFvG4h8yvrpmb1lzL1w"
          />
          <title>
            Products by GG Cleaning Ireland | Window Cleaning, Carpet Cleaning,
            Contract Cleaning in Castlebar, Ireland
          </title>
          <description>
            GG Cleaning provides a select few products to help keep you clean &
            sanitised.
          </description>
        </Helmet>

        <div>
          {/* Start header section */}
          <section className="header-section">
          <a
              href="/contact"
              className="reqBtn _btn-brand _btn"
            >
              Contact us to order!
            </a>

            <div className="d-flex align-items-center">
              <div className="col-lg-12 header-left order-lg-1 order-md-2 order-2 pad0">
                <div className="home-banner serviceBanner half-height-banner underlay">
                  <div className="home-title">
                    <h2>GG Cleaning Product Range</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End header section */}
          <section className="service-wrapper">
            <PageIntro
              // icon="../../windowiconred.png"
              heading="Contact GG Cleaning to order any of our cleaning & sanitising products listed below"
            />

            <p style={{ textAlign: `center`, marginTop: `-20px` }}>
              &gt; All prices excl VAT &lt;
              <br />
              &gt; Ireland Delivery: &euro;6.50ex &lt;
            </p>

            {/* Start ImageContentRow section */}
            <AlternatingRowsContent
              imgAlignment="right"
              fgImg="../products/pearl-hand-soap.jpg"
              fgImgAlt="foreground-image"
              heading="Pearl Hand Soap"
              paragraph="Our first line of defense against all these pesky germs floating about. Since we are washing our hands at least 3 times a minute, this gentle, subtly fragranced hand cleanser will keep it clean without drying up your skin. Because there’s nothing pleasant about shaking a scaly hand. Oh wait we’re bumping elbows now. Well, if you’re washing up to your elbows at least you will have a silky soft elbow. Because beauty is just as important as cleanliness, is it not?"
              price="12.88"
            />

            <AlternatingRowsContent
              imgAlignment="left"
              fgImg="../products/antibac-soap.jpg"
              fgImgAlt="foreground-image"
              heading="Antibac Soap"
              paragraph="A broad spectrum hand cleanser that so speedily and thoroughly eradicates those unwanted nasties that they won’t have a moment to even think about setting up camp and starting a family. It contains an active bactericide, keeping those anti-bacterial soldiers fighting off the baddies long after your last wash."
              price="14.90"
            />

            <AlternatingRowsContent
              imgAlignment="right"
              fgImg="../products/hand-san.jpg"
              fgImgAlt="foreground-image"
              heading="Hand San"
              paragraph="Once again we’re eliminating germ families, but waterlessly this time. An anti-microbial hand sanitiser based on iso-propanol which instantly and completely annihilates Gram-negative and Gram-positive bacteria, yeasts, moulds and viruses. And Triclosan helps it hang around on your skin, so you can confidently say long after each use: “I am still a germ fighting warrior!” Contains 70% alcohol."
              price="35.42"
            />

            <AlternatingRowsContent
              imgAlignment="left"
              fgImg="../products/steri-san.jpg"
              fgImgAlt="foreground-image"
              heading="Steri San"
              paragraph="Have you ever wondered how we survived swimming in public pools as children? Chlorine, baby, chlorine. So here we have a liquid bleach and sanitising surface disinfectant based on sodium hypochlorite. Containing a minimum of 4.5% chlorine so your germ-free status is safely in the bag with this one on your side."
              price="13.75"
            />

            <AlternatingRowsContent
              imgAlignment="right"
              fgImg="../products/quatron.jpg"
              fgImgAlt="foreground-image"
              heading="Quatron"
              paragraph="And now for the strong stuff. A heavy duty hard surface sanitiser based on a quaternary ammonium compound which can be used at the final stage of the cleaning process in the catering and food processing industry. This broad spectrum bacteria/yeast/fungi zapping blend of bactericide contains a non-ionic surfactant which ensures long-lasting contact with surfaces. You’re now ready for World War III."
              price="14.20"
            />
          </section>

          {/* Start request section */}
          <CalltoAction
            bgimage="../../footer-baner.png"
            btnbgcolor="#D91F3D"
            btncolor="#fff"
            heading={"Contact GG Cleaning to order your cleaning products"}
            action="/contact"
            btnval="Order Now"
          />
          {/* End request section */}
        </div>
      </Layout>
    );
  }
}

export default Products;
