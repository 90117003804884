import React from "react";
export class AlternatingRowsContent extends React.Component {
  render() {
    const count = this.props.bgImg ? 2 : 1;

    if (
      this.props.imgAlignment === "right" ||
      this.props.imgAlignment === undefined
    ) {
      return (
        <section className="serviceBox">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 col-lg-6">
                <div className="service-page-content  pad-right">
                  {this.props.heading && <h4>{this.props.heading}</h4>}
                  {this.props.price && (
                    <h6 style={{ margin: `-15px 0 20px`, fontSize: `170%` }}>
                      &euro;{this.props.price}
                    </h6>
                  )}
                  {this.props.paragraph && <p>{this.props.paragraph}</p>}
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div
                  className={`cleaningService_item _wash image-count-${count}`}
                >
                  {this.props.bgImg && (
                    <img
                      src={this.props.bgImg}
                      className={`bg-img ${this.props.bgImgAlt}`}
                      alt={this.props.bgImgAlt}
                    />
                  )}
                  {this.props.fgImg && (
                    <img src={this.props.fgImg} alt={this.props.fgImgAlt} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section className="serviceBox _traditionalBox">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 col-lg-6">
                <div className="cleaningService_item _traditional">
                  {this.props.bgImg && (
                    <img
                      src={this.props.bgImg}
                      className={`bg-img ${this.props.bgImgAlt}`}
                      alt={this.props.bgImgAlt}
                    />
                  )}
                  {this.props.fgImg && (
                    <img src={this.props.fgImg} alt={this.props.fgImgAlt} />
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="service-page-content pad-left">
                  {this.props.heading && <h4>{this.props.heading}</h4>}
                  {this.props.price && (
                    <h6 style={{ margin: `-15px 0 20px`, fontSize: `170%` }}>
                      &euro;{this.props.price}
                    </h6>
                  )}
                  {this.props.paragraph && <p>{this.props.paragraph}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
export default AlternatingRowsContent;
